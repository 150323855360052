<script>
import {animateCountChange} from '@/utils/animateCountIncrease';

export default {
  props: {
    cash: {
      type: Number,
      required: true,
      default: 0,
    },
    isPayback: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      displayCash: this.cash,
    }
  },
  watch: {
    cash: {
      immediate: true,
      handler(value, _value) {
        if (_value === undefined) {
          this.displayCash = this.cash;
          return;
        }

        if (this.isPayback) {
          animateCountChange(_value, value, this.updateCash);
        } else {
          this.displayCash = this.cash;
        }
      }
    }
  },
  methods: {
    updateCash(value) {
      this.displayCash = value;
    },
  },
}
</script>

<template>
  <div class="player_point">{{ displayCash | formatCash }}</div>
</template>

<style scoped>
.player_point {
  min-width: 6rem;
}
</style>