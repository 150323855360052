const MIN_ANIMATION_RANGE = 100;
const MIN_DURATION = 500;
const MAX_DURATION = 1200;
const STEP_MULTIPLIER = 0.001;

export function animateCountChange(startValue, endValue, callback) {
    const range = Math.abs(endValue - startValue);
    const isIncreasing = endValue > startValue;

    if (range < MIN_ANIMATION_RANGE) {
        callback(endValue);
        return;
    }

    const duration = Math.min(Math.max(range * 10, MIN_DURATION), MAX_DURATION);
    const start = performance.now();
    const step = calculateStep(range, duration);

    function animate(currentTime) {
        const elapsed = currentTime - start;
        const progress = Math.min(elapsed / duration, 1);

        const currentValue = isIncreasing
            ? startValue + range * progress
            : startValue - range * progress;
        const formattedValue = Math.round(currentValue / step) * step;

        callback(formattedValue);

        if (progress < 1) {
            requestAnimationFrame(animate);
        } else {
            callback(endValue);
        }
    }

    requestAnimationFrame(animate);
}

function calculateStep(range, duration) {
    const steps = Math.max(range / (duration * STEP_MULTIPLIER), 1);
    return Math.round(range / steps);
}